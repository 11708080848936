import React from 'react'

function PrivacyBlack() {
    const path = window.location.pathname; 
    const firstSegment = path.split('/')[1];
  return (
    <div>
         <h1>Privacy Policy</h1>
    <p>We are dedicated to keeping your personal information secure and private. When you use our website and services, we will collect, use, and disclose information as described in this Privacy Policy. Review this policy for a minute, and let us know if you have any questions or issues.</p>

    <h2>Information We Collect</h2>
    <p>We may collect various types of information from you when you interact with our website:</p>

    <h3>1. Personal Information</h3>
    <p>
        When you voluntarily give us personal information via forms or other channels of communication, we may gather it, including your name, email address, and contact information. In order to satisfy your needs, address your questions, and give you the best service possible, this information is used.
    </p>

    <h3>2. Non-Personal Information</h3>
    <p>
        Non-personal data like your IP address, operating system, browser type, and website usage statistics may also be gathered by us. This data is combined and used for internal analysis of trends, website administration, user movement tracking, and demographic data collection. It aids in enhancing the performance and usefulness of our website.
    </p>

    <h2>How We Use Your Information</h2>
    <p>We utilize the data we gather for the following purposes:</p>
    <ul>
        <li>Deliver and uphold our services </li>
        <li>Enhance, customize, and broaden our offerings</li>
        <li>Answer your queries, remarks, and requests.</li>
        <li>Talk to you about the goods, services, deals, and specials.</li>
        <li>Track and evaluate patterns, applications, and actions.</li>
        <li>Stop, identify, and look into any fraudulent or unlawful activity.</li>
        <li>Maintain the integrity and security of our website.</li>
        <li>Observe your legal obligations</li>
    </ul>

    <h2>Information Sharing and Disclosure</h2>
    <p>We respect your privacy and do not sell or disclose your personal information to third parties, except in the following circumstances:</p>
    <ul>
        <li><strong>Service Providers:</strong> To carry out tasks and handle your information on our behalf, we might work with reputable third-party service providers. These service providers are required to keep your information private and confidential; they may only use it to carry out tasks on our behalf.</li>
        <li><strong>Legal Requirements:</strong>  If required by law or in response to legitimate demands from public authorities (such as a court or government agency), we may be obliged to release your information.</li>
        <li><strong>Business Transfers:</strong> Your information might be given to the acquiring company in the case of a merger, acquisition, or sale of our assets. </li>
        <li><strong>Consent:</strong> You may give us permission to share your information or request us to do so.</li>
    </ul>

    <h2>Data Retention</h2>
    <p>Unless a longer retention time is mandated or authorized by law, we will keep your personal information for as long as it takes to achieve the goals specified in this privacy policy.</p>

    <h2>Your Rights</h2>
    <p>You have the right to:</p>
    <ul>
        <li>Obtain, amend, or remove your personal data.</li>
        <li>Retract your approval at any moment.</li>
        <li>Refuse to have your personal information processed.</li>
        <li>Get a copy of the data we currently have on you.</li>
        <li>Restrict or minimize the applications of your personal data to us.</li>
    </ul>

    <p>Please use the contact details included at the conclusion of this privacy policy to get in touch with us in order to exercise any of these rights.</p>

    <h2>Security</h2>
    <p>We take appropriate precautions to guard against unauthorized access, use, or disclosure of your personal information. Please note, however, that we are unable to guarantee complete security and that there is no 100% safe way to transmit data over the internet or store electronic files.</p>

    <h2>Third-Party Links</h2>
    <p>Links to external websites or services that are not run by us may be found on our website. We do not control, and we disclaim all liability for, the privacy practices, content, or policies of any third-party websites that you link to from our website.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We might occasionally make changes to our privacy statement. Any updates will be reflected on this page, along with a revision to the "Last Updated" date at the top. It is recommended that you routinely check this Privacy Policy for any revisions.</p>


    </div>
  )
}

export default PrivacyBlack

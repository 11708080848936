import React from 'react'
import './Playzoneau.css'; 
import nlFlg from '../images/nlFlg.png'
import back2 from '../images/back2.jpg'
import back3 from '../images/back3.jpg'
import back4 from '../images/back4.jpg'
import back5 from '../images/back5.jpg'
import background from '../images/back7.jpg'
import { Helmet } from 'react-helmet-async';
import favicon from './../images/ausfl.png'

function Guidewinnl() {
    const path = window.location.pathname; 
    const firstSegment = path.split('/')[1];

  return (
    <div>
    <Helmet>
        <title>Best Casino Hotels in {firstSegment=='au'? 'Australia':'Netherlands'} </title>
        <meta name="description" content={`Best Casino Hotels in ${firstSegment=='au' ? 'Australia':'Netherlands'}`} />
        <link rel="icon" type="image/x-icon" href={firstSegment=='au'?favicon:nlFlg} />
    </Helmet>
    <nav class="navbar navbar-expand-lg sticky-top bg-white">
        <div class="container">
            <a class="navbar-brand" href="#"><img src={firstSegment=='au'?favicon:nlFlg} class="top-logo" alt="logo.com"/> <span>Travel Agency in {firstSegment=='au'? 'Australia':'Netherlands'}</span> </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#home">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#list">List</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#contact-us">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <section id="home" class="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
                    <h3 class="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Visit the Best Casino Hotels in the {firstSegment=='au'? 'Australia':'Netherlands'}</h3>
                    <p>Dreaming of a getaway that combines home-like luxury with casino excitement? Look no further - the {firstSegment=='au'? 'Australia':'Netherlands'} offers a selection of top-tier casino hotels that promise an unforgettable journey. Whether you're looking for an exclusive rooftop terrace or a luxurious suite with breathtaking views, these carefully selected destinations provide the perfect blend of luxury, entertainment, and the chance to test your luck at the gaming tables.</p>
                </div>
            </div>
        </div>
    </section>

    <section id="list" class="hotel section-padding padding-top-80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-howtouse text-left pb-5">
                        <div class="container-body">
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back2} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Ville Resort & Casino</a></h5>
                                            <p class="card-text">For those who appreciate both tranquility and the thrill of gaming, The Ville Resort & Casino offers the perfect balance. Relax in minimalist yet stylish rooms, clear your mind before diving into the world of gambling. From 9 to 5, experience the excitement of 370 slot machines and over 20 table games, providing endless entertainment during your stay. But The Ville Resort is more than a gambler's paradise - it's an oasis of romantic serenity.</p>
                                            <a href="#" class="list-btn">Book Now</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Very Good</h4>
                                                    <p>100% verified reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back4} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Pullman Reef Hotel Casino</a></h5>
                                            <p class="card-text">Experience pure sophistication at the Pullman Reef Hotel Casino. Imagine relaxing in a rooftop pool surrounded by swaying palms while enjoying breathtaking views of the reef. The accommodations go beyond the ordinary, with rooms featuring hot tubs and private balconies with panoramic views.</p>
                                            <a href="#" class="list-btn">Book Now</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Very Good</h4>
                                                    <p>100% verified reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back3} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Star Gold Coast</a></h5>
                                            <p class="card-text">Looking for a royal retreat? The Star Gold Coast is the answer. Enjoy luxurious suites that redefine opulence. Each room is a haven of refined comfort. Discover culinary excellence at eight restaurants and bars, savoring exquisite creations that delight your taste buds.</p>
                                            <a href="#" class="list-btn">Book Now</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Very Good</h4>
                                                    <p>100% verified reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back5} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Crown Melbourne</a></h5>
                                            <p class="card-text">Crown Melbourne welcomes you with a variety of choices, whether you're looking for cityscapes or river panoramas. Enjoy luxury in magnificent villas with large windows capturing breathtaking views. If you're looking for affordable luxury without compromising on style, you'll be delighted by the thoughtfully designed rooms.</p>
                                            <a href="#" class="list-btn">Book Now</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Very Good</h4>
                                                    <p>100% verified reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="contact-us" class="hotel section-padding bg-light">
        <div class="container">
            <div class="row align-items-center">
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="560" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11984.056463345145!2d4.916102077149423!3d52.391162236177465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sen!4v1731670575718!5m2!1sen!2sen" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="bg-light p-2 text-center">
        <div class="container padding-20">
            <div class="social-icon">
                <a href="#" class="fa fa-facebook"></a>
                <a href="#" class="fa fa-instagram"></a>
            </div>
            <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Privacy Policy</a>
            <p class="text-dark footer-text">© 2023 Copyright GuideWinNl
.com All rights reserved.</p>
        </div>
    </footer>
</div>

  )
}

export default Guidewinnl

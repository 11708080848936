import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import logo from './images/logo.png';
import menu from './images/free-icon-menu-4254068.png';
import auFlg from './images/australia-logo.jpg';


function HeaderBlack() {
  const { firstSegment } = useParams(); // Dinamik segmenti alır
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
console.log(firstSegment);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div
        style={{
          borderRadius: '24px',
          border: '#0f0d1b',
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
        className="container"
      >
        <Link className="navbar-brand" to={`/${firstSegment}`}>
          <img src={logo} alt="logo" /> <span>GuideWinNl</span>
        </Link>
        <button className="navbar-toggler" type="button" id="burgerMenuButton">
          <img
            id="burgerIcon"
            src={menu}
            alt="menu"
            onClick={toggleMenu}
            style={menuOpen ? { filter: "invert(100%)" } : {}}
          />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-item nav-link" to={`/${firstSegment}`}>
              Top Online Casino
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/slot-machines`}>
              Slot Machines
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/roulette`}>
              Roulette
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/poker`}>
              Poker
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/live-casino`}>
              Live Casino
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/blackjack`}>
              Blackjack
            </Link>
          </div>
        </div>
        <div id="mobileMenu" className={`mobile-menu ${menuOpen && 'show'}`}>
          <div className="navbar-nav white-links flex-column">
            <Link className="nav-item nav-link" to={`/${firstSegment}`}>
              Top Online Casino
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/slot-machines`}>
              Slot Machines
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/roulette`}>
              Roulette
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/poker`}>
              Poker
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/live-casino`}>
              Live Casino
            </Link>
            <Link className="nav-item nav-link" to={`/${firstSegment}/blackjack`}>
              Blackjack
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HeaderBlack;
